
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "super-admin-reset-calender",
  components: {
    Field,
    Datatable,
  },
  data() {
    return {
      api_url: "",
      trainee: {
        entity_id: "",
        training_institute_id: "",
        tranche_id: "",
        course_info_id: "",
      },
      
      institutekey: 0,
      batchkey: 0,
      associations: [],
      institutes: [],
      tranches: [],
      courses: [],
      eligibleBatchList: [],
      ineligibTrainingCalendar: [],
      notenrollTraineeList: [],
      notEnComponentKey: 0,
      enComponentKey: 0,
      load: false,
      loading: false,
      tabIndex: ref(0),
      btnCheck: false,
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
  },
  methods: {
    async associationList() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.associations = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("institute/list?entity_id=" + entity_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getCourseList() {
      this.load = true;
      await ApiService.get(
        "course/list?entity_id=" +
          this.trainee.entity_id +
          "&tranche=" +
          this.trainee.tranche_id +
          "&institute_info_id=" +
          this.trainee.training_institute_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getEligibleBatchList() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      if(entity_id && institute_info_id && this.trainee.tranche_id && this.trainee.course_info_id){
        this.load = true;
        await ApiService.get("configurations/super_admin/eligible_calender_batch?entity_id=" + entity_id +'&tranche_id=' + this.trainee.tranche_id +'&institute_info_id=' + institute_info_id +'&course_info_id=' + this.trainee.course_info_id)
          .then((response) => {
            this.eligibleBatchList = response.data.data;
            this.institutekey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
        });
      }else{
        this.tabIndex = 0;
        Swal.fire({
          title: "Error!",
          html: "Please select required field.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },

    async getIneligibleBatchList() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
    if(entity_id && institute_info_id && this.trainee.tranche_id && this.trainee.course_info_id){
        this.load = true;
        await ApiService.get("configurations/super_admin/ineligible_calender_batch?entity_id=" + entity_id +'&tranche_id=' + this.trainee.tranche_id +'&institute_info_id=' + institute_info_id +'&course_info_id=' + this.trainee.course_info_id)
          .then((response) => {
            this.ineligibTrainingCalendar = response.data.data;
            this.batchkey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
        });
      }else{
        this.tabIndex = 0;
        Swal.fire({
          title: "Error!",
          html: "Please select required field.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },
    Reset(id) {
     let data = {
        batch_info_id: id,
      };
      if (id) {
        Swal.fire({
          title: "Are you sure you want to reset this batch?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Reset!",
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post("configurations/super_admin/batch_calender_reset", data)
              .then((response) => {
                Swal.fire("Success!", response.data.data, "success").then(() => {
                   this.getEligibleBatchList();
                });
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      }
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));

      if(this.tabIndex == 1){
        this.getEligibleBatchList();
      }
      if(this.tabIndex == 2){
        this.getIneligibleBatchList();
      }

    },
    
  },
});
